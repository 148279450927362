import React from 'react';
import NavBarComponent from './components/navbar/navbar';
import Timeline from './components/timeline/timeline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBarComponent />
        <Routes>
          <Route path="/cgc/publictracker-1" element={<Timeline googleSheetsUrl="https://docs.google.com/spreadsheets/d/e/2PACX-1vTiWSBRKzxr5pRHECCsHDAfggrCXYelaYRtiAYrY2nHjW1_CoF_iqeQz01qqO06dMYn_hxzkhPuTezS/pub?gid=1339920591&single=true&output=csv" />} />
          <Route path="/cgc/publictracker-2" element={<Timeline googleSheetsUrl="https://docs.google.com/spreadsheets/d/e/2PACX-1vTiWSBRKzxr5pRHECCsHDAfggrCXYelaYRtiAYrY2nHjW1_CoF_iqeQz01qqO06dMYn_hxzkhPuTezS/pub?gid=1953520688&single=true&output=csv" />} />
          {/* Add more routes as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
