import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from '../../img/pikablue-game-corner-logo.svg';
import './navbar.css'
import { Link } from 'react-router-dom';

const NavBarComponent = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="https://pikabluegc.com">
                    <img
                      alt="Pikablue Game Corner Logo"
                      src={logo}
                      width="30"
                      height="30"
                      className="d-inline-block align-top"
                    />{' '}
                    Pikablue Game Corner
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="https://pikabluegc.com/pages/cgc-card-reholder-service">CGC Submission Form</Nav.Link>
                        <Nav.Link href="https://pikabluegc.com/blogs/news">News & Articles</Nav.Link>
                        <NavDropdown title="Public Trackers" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/cgc/publictracker-1">CGC 1 - Reholder</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/cgc/publictracker-2">CGC 2 - Reholder</NavDropdown.Item>
                            {/* Add more trackers as needed */}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBarComponent;
