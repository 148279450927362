import React, { useEffect, useState } from 'react';
import './timeline.css'; // Import the CSS from a separate file

const events = [
  {label: '0', text: 'Open'},
  {label: '1', text: 'Received'},
  {label: '2', text: 'Input'},
  {label: '3', text: 'Sent to CGC'},
  {label: '4', text: 'Received by CGC'},
  {label: '5', text: 'Grading Processing'},
  {label: '6', text: 'Grading Complete'},
  {label: '7', text: 'Returning'},
  {label: '8', text: 'Received by PikablueGC'},
  {label: '9', text: 'Outbound'},
  {label: '10', text: 'Complete'}
];

const getLastCompletedEvent = (row) => {
  for (let i = events.length - 1; i >= 0; i--) {
    if (row[events[i].text] === '1') {
      return events[i].text;
    }
  }
  return '';
};

const Timeline = ({googleSheetsUrl}) => {
  const [data, setData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  
  useEffect(() => {
    fetch(googleSheetsUrl)
      .then(response => response.text())
      .then(csv => {
        const parsedData = parseCsv(csv);
        setData(parsedData);
        // Set the initial value of selectedEvent to the last event with a value of '1'
        setSelectedEvent(getLastCompletedEvent(parsedData[0]));
      });
  }, [googleSheetsUrl]);

  const parseCsv = (csv) => {
    const rows = csv.split('\n');
    const headers = rows[0].split(',');
    return rows.slice(1).map(row => {
      const values = row.split(',');
      let obj = {};
      headers.forEach((header, i) => obj[header] = values[i]);
      return obj;
    });
  };

  const descriptions = {
    'Open': 'Now accepting submissions. Submit your cards.',
    'Received': 'The CGC Graded Cards are heading our way.',
    'Input': 'Inputting the CGC Graded Cards.',
    'Sent to CGC': 'Shipping the CGC Graded Cards to USA.',
    'Received by CGC': 'CGC USA has the confirmed that the CGC Graded Cards have arrived.',
    'Grading Processing': 'CGC has started Grading or Reholding the submission.',
    'Grading Complete': 'CGC has completed Grading or Reholding the submission.',
    'Returning': 'The CGC Graded Cards are returning to Australia!',
    'Received by PikablueGC': 'We have the CGC Graded Cards. We will reach out to everyone shortly.',
    'Outbound': 'Sending out everyone orders.',
    'Complete': 'Thank you for Grading with us. Another successful submission complete.'
  };

  return (
    <div id="root">
      {data.map((row, index) => (
        <div className="flex-parent" key={index}>
          <div className="input-flex-container">
            {events.map((event, eventIndex) => (
              <React.Fragment key={eventIndex}>
                <input 
                  type="radio" 
                  name="timeline-dot" 
                  data-description={event.label} 
                  checked={row[event.text] === '1'} 
                  readOnly
                  onClick={() => setSelectedEvent(event.text)}
                />
                <div 
                  className={`dot-info ${row[event.text] === '1' ? 'complete' : 'incomplete'}`} 
                  data-description={event.label}
                >
                  <span className="year">{event.label}</span>
                  <span className="label">{event.text}</span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
      <div id="description-container">
        {selectedEvent && (
          <p>{descriptions[selectedEvent]}</p>
        )}
      </div>
    </div>
  );
};

export default Timeline;
